<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-card-group deck>
            <b-card
                :header="$t('settings.general.group.common')"
                header-tag="header"
            >
                <b-card-text>
                    <b-row>
                        <b-col cols="6">
                            <zw-select-group v-model="form.default_locale"
                                             name="default_locale"
                                             :label-prefix="labelPrefix"
                                             :options="getLocales()"
                                             class="capitalize_select"
                                             text-field="name"
                                             value-field="code"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('settings.general.label.android_apk')">
                                <b-button variant="success" @click="downloadApk">
                                    <font-awesome-icon :icon="['fab', 'android']"/>
                                </b-button>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <zw-input-group v-model="form.storno_rechnungsnummer"
                                            name="storno_rechnungsnummer"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-input-group v-model="form.gutschriftnummer"
                                            name="gutschriftnummer"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <zw-date-group v-model="form.kassenbuch_start_date"
                                           name="kassenbuch_start_date"
                                           :label-prefix="labelPrefix"
                            ></zw-date-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-input-group v-model="form.kassenbuch_start_sum"
                                            name="kassenbuch_start_sum"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <zw-input-group v-model="form.store_vpe_type"
                                            name="store_vpe_type"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-input-group v-model="form.store_vpe_unit"
                                            name="store_vpe_unit"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <zw-input-group v-model="form.store_cash_account"
                                            name="store_cash_account"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <zw-select-group v-model="form.store_list_type"
                                             name="store_list_type"
                                             :label-prefix="labelPrefix"
                                             :options="getListTypes()"
                                             class="capitalize_select"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-select-group v-model="form.print_pos_name_list"
                                             name="print_pos_name_list"
                                             :label-prefix="labelPrefix"
                                             :options="{
                                            0: $t('common.button.no'),
                                            1: $t('common.button.yes'),
                                         }"
                            ></zw-select-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <zw-select-group v-model="form.vat_check"
                                             name="vat_check"
                                             :label-prefix="labelPrefix"
                                             :options="{
                                            0: $t('common.button.no'),
                                            1: $t('common.button.yes'),
                                         }"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-select-group v-model="form.vat_auto_check"
                                             name="vat_auto_check"
                                             :label-prefix="labelPrefix"
                                             :options="{
                                            0: $t('common.button.no'),
                                            1: $t('common.button.yes'),
                                         }"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-select-group v-model="form.month_invoice_date_attribute"
                                             name="month_invoice_date_attribute"
                                             :label-prefix="labelPrefix"
                                             :options="getOfferingDateFields()"
                            ></zw-select-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <zw-select-group v-model="form.default_customer_form"
                                             name="default_customer_form"
                                             :label-prefix="labelPrefix"
                                             :options="getCustomerForms()"
                                             class="capitalize_select"
                            ></zw-select-group>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-card-group>

        <b-row>
            <b-col sm="12" class="text-xs-center mt-2">
                <b-row align-h="end">
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsGeneral',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            form: {},
            labelPrefix: 'settings.general.label.',
        }
    },
    methods: {
        ...mapGetters('Settings', ['getGeneral']),
        ...mapGetters('CommonData', ['getListTypes', 'getLocales', 'getDocumentLayouts', 'getCustomerForms', 'getOfferingDateFields', 'getLastAppVersion']),
        shown() {
            this.loading = true
            const settingsGeneral = this.$store.dispatch('Settings/fetchGeneral');

            Promise.all([settingsGeneral]).then(() => {
                this.form = this.getGeneral()
                this.loading = false
            });
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Settings/save', this.form).then((response) => {
                        this.afterSave(response)
                    }).catch(errors => {
                        this.$refs['observer'].setErrors(errors)
                        this.showValidationError()
                    })
                } else {
                    this.showValidationError()
                }
            })
        },
        afterSave(response) {
            if (response.status == 200 || response.status == 201) {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.success_updated'),
                    'success'
                )
                this.shown()
            }
            this.$root.afterSettingsUpdate()
        },
        getCountry(local) {
            let result = local;
            Object.entries(this.getLocales()).forEach(function (value, index) {
                if (value[1].code == local) {
                    result = value[1].name;
                }
            });
            return result;
        },
        downloadApk() {
            window.open(this.getLastAppVersion(), '_blank').focus();
        }
    },
    mounted() {
        this.shown()
    }
}
</script>